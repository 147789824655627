import * as React from "react"
import { FC } from "react"
import { Heading, Text } from "theme-ui"

import { Layout } from "components/Layout"
import { SEO } from "components/Seo"

export const PageNotFound: FC = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Heading variant="h1">NOT FOUND</Heading>
    <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
  </Layout>
)

export default PageNotFound
